<template id="vue-autocomplete">
    <div class="autocomplete-form" 
         
         @keydown.down="selectedIndex = selectedIndex < valuesShowing.length - 1 ? selectedIndex + 1 : selectedIndex;" 
         @keydown.up="selectedIndex = selectedIndex > - 1 ? selectedIndex - 1 : selectedIndex;"
         @keydown.enter="onEnterClicked"
         @focusin="visible=true" 
         @focusout="visible=false; selectedIndex = 0;">
    <div class="autocomplete-form-input-elements">
      <input id="autocomplete-form-input"
             placeholder="Procurar Jogador..."
             autocomplete="off"
             class="w-100 form-control"
             @input="onInputHandler" 
             v-model="text"  
             type="text">  
    </div>
    <div class="list-group list-group-flush autocomplete-form-items" :class="{hide: !visible}">
        <button v-for="(val,index) in valuesShowing" 
                :key="index" 
                :data-id="index"
                class="list-group-item list-group-item-action" 
                :class="{active:selectedIndex == index}" 
                @click="onClickHandler"
                @mouseover="selectedIndex = index">
          
          {{val}}
        </button>
      </div>
    </div>
  </template>

<script>
export default {
  name: "VueAutocomplete",
  props: {
    values: Array,
    value: String
  },
  data(){
    return{
      text: "",
      valuesShowing: [],
      visible: false,
      selectedIndex: 0
    }
  },
  methods: {
    onInputHandler(e){
      this.$data.valuesShowing = this.$props.values.filter(a => a.includes(e.target.value)).splice(0,5);
      this.$emit('input', this.$data.text);
    },
    onClickHandler(e){
      this.$data.text = e.target.innerHTML.trim();
      this.selectedIndex = e.target.dataset["id"];
      this.$emit('input', this.$data.text);
      e.target.blur();
    },
    onEnterClicked(e){
      this.$data.text = this.$data.valuesShowing[this.$data.selectedIndex].trim();
      this.$emit('playerSelected', this.$data.text);
      e.target.blur();
    }
  }
};
</script>

<style>
.autocomplete-form-input-elements {
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
}
  
.autocomplete-form-items {
  position: relative;
  width: auto;
  border: 1px solid lightgrey;
}
  
  input {
    margin-bottom: 1px;
  }
  .hide {
    display: none;
  }

</style>