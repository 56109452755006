<template>
<div class="row g-3">
    <div class="col-md-12">
      <label for="name" class="form-label">Nome</label>
      <Field type="text" class="form-control" id="name" v-model="localForm.name" name="name" :rules="isRequired"></Field>
    </div>
    <div class="col-md-4">
      <label for="inputPlayers" class="form-label">N. Jogadores</label>
      <select id="inputPlayers" class="form-select" v-model="localForm.numPlayers" :disabled="ongoing" >
        <option>2</option>
        <option>3</option>
        <option selected>4</option>
        <option>5</option>
        <option>6</option>
      </select>
    </div>
    <div class="col-md-4" v-if="false">
      <label for="inputAsk" class="form-label">Convidar</label>
      <VueAutocomplete :values="users" v-model="invitedPlayer" @selected="playerSelected"></VueAutocomplete>
    </div>
    <div class="col-md-4" v-if="false">
      <label for="invites" class="form-label">Jogadores</label>
      <div id="invites" class="d-flex gap-2">
        <button class="btn btn-outline-secondary d-inline-flex btn-sm" type="button" tooltip="Remover">
          Primary icon
          <font-awesome-icon icon="times" />
        </button>
        <button class="btn btn-outline-secondary d-inline-flex align-items-center btn-sm" type="button">
          Secondary icon
          <svg class="bi" width="16" height="16"><use xlink:href="#x-lg"></use></svg>
        </button>
      </div>
    </div>
    <div class="col-md-4">
      <label for="inputMap" class="form-label">Mapa</label>
      <select id="inputMap" class="form-select" v-model="localForm.map" :disabled="ongoing" >
        <option selected>Random All</option>
        <option>Random Official</option>
        <option>Tharsis</option>
        <option>Hellas</option>
        <option>Elysium</option>
        <option>Amazonis</option>
        <option>Arabia Terra</option>
        <option>V.Borealis</option>
        <option>Terra Cimmeria</option>
      </select>
    </div>
    <div class="col-md-12">
      <h4>Pontuação</h4>
    </div>
    <div class="col-12">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="cbPtvisSwitch" v-model="localForm.ptvis" :disabled="ongoing" >
        <label class="form-check-label" for="cbPtvisSwitch">Pontos {{localForm.ptvis ? "visíveis" : "escondidos"}}</label>
      </div>
    </div>
    <div class="col-md-12">
      <h4>Expansões</h4>
    </div>
    <div class="col-md-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbPrelude" v-model="localForm.prelude" :disabled="ongoing" >
        <label class="form-check-label" for="cbPrelude">Prelude</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbVenus" v-model="localForm.venus" :disabled="ongoing" >
        <label class="form-check-label" for="cbVenus">Venus</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbColonies" v-model="localForm.colonies" :disabled="ongoing" >
        <label class="form-check-label" for="cbColonies">Colonias</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbfanMade" v-model="localForm.fanMade" :disabled="ongoing" >
        <label class="form-check-label" for="cbfanMade">Fan made corps</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbTurmoil" v-model="localForm.turmoil" :disabled="ongoing" >
        <label class="form-check-label" for="cbTurmoil">Turmoil</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbTurmoilA" v-model="localForm.turmoilA" :disabled="ongoing" >
        <label class="form-check-label" for="cbTurmoilA">Turmoil Agendas</label>
      </div>
      <div class="col-md-2 offset-md-3 form-select-inline">
        <select id="inputAgendas" class="form-select" v-model="localForm.turmoilAV" v-if="localForm.turmoilA" :disabled="ongoing" >
          <option selected>Chairman</option>
          <option>Random</option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbTrajectory" v-model="localForm.trajectory" :disabled="ongoing" >
        <label class="form-check-label" for="cbTrajectory">Trajectory</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbMoon" v-model="localForm.moon" :disabled="ongoing" >
        <label class="form-check-label" for="cbMoon">Moon</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="cAres" v-model="localForm.ares" :disabled="ongoing" >
        <label class="form-check-label" for="cAres">Ares</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="cbmaxRating" v-model="localForm.maxRating" :disabled="ongoing" >
        <label class="form-check-label" for="cbmaxRating">Limitar Rating</label>
      </div>
    </div>
    <div class="col-12" v-show="localForm.maxRating">
      <div class="row">
        <div class="col-6">
          <label for="customRange1" class="form-label">Min rating - {{localForm.minLimit}}</label>
          <input type="range" class="form-range" min="0" max="100" id="customRange1" v-model.number="localForm.minLimit" :disabled="ongoing" >
        </div>
        <div class="col-6">
          <label for="customRange2" class="form-label">Max rating - {{localForm.maxLimit}}</label>
          <input type="range" class="form-range" min="0" max="100" id="customRange2" v-model.number="localForm.maxLimit" :disabled="ongoing" >
        </div>
      </div>
      <div v-if="checkRange" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        Os valores min e max são inválidos!
      </div>
    </div> 
    <div class="col-12">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="cbAnonSwitch" v-model="localForm.anon" :disabled="ongoing" />
        <label class="form-check-label" for="cbAnonSwitch">Anonimo</label>
      </div>
    </div>
</div>
</template>

<script>
import VueAutocomplete from "./Autocomplete.vue";
import UserService from "../../services/user.service";
import { Field } from "vee-validate";

export default {
  name: "GameFormInputs",
  components: {
    VueAutocomplete,
    Field
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    ongoing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      users: [],
      invitedPlayer: "",
      localForm: { ...this.form }, // Create a local copy of the form
    };
  },
  watch: {
    form: {
        immediate: true,
        deep: true,
        handler(newForm){
          if (JSON.stringify(newForm) !== JSON.stringify(this.localForm)) {
            this.localForm = { ...newForm };
          }
        }
    },
    localForm: {
      deep: true,
      handler(newForm) {
        this.$emit("update:form", newForm); // Emit updates to the parent
      },
    },
  },
  computed: {
    checkRange(){
      if(!this.localForm.maxRating) return false;

      let userRating = (this.$store.state.auth.user.openskill || 0) + 50
      return this.localForm.maxLimit < userRating || this.localForm.minLimit > userRating || this.localForm.minLimit >= this.localForm.maxLimit ; 
    }
  },
  methods:
  {
    playerSelected(){
      console.log(this.invitedPlayer);
    },
    mounted() {
      UserService.getUsers().then(
        (response) => {
          console.log
          this.users = response.data.map(x => x.username);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
};
</script>